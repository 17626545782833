

// header style here

.header-top-one-wrapper{
    background: var(--color-primary-2);
    padding: 0;
    @media #{$sm-layout} {
        display: none;
    }
    .header-top-one{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .left-information{
        display: flex;
        align-items: center;
        gap: 30px;
        a{
            color: #fff;
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }
    .right-information{
        display: flex;
        align-items: center;
        gap: 20px;
        .rts-dropdown-menu{
            padding: 0;
            margin: 0;
            .has-child-menu{
                list-style: none;
                margin: 0;
                position: relative;
                transition: all .4s;
                &:hover{
                    .sub-menu{
                        visibility: visible;
                        opacity: 1;
                        transform: translateZ(0) translateY(0);
                    }
                }
                a{
                    color: var(--color-white);
                    font-size: 14px;
                    padding: 8px 0;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    img{
                        width: 20px;
                    }
                }
                .sub-menu{
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    visibility: hidden;
                    opacity: 0;
                    background: var(--color-white);
                    transform: translateZ(0) translateY(15px);
                    border-radius: 4px;
                    transition: all .4s;
                    box-shadow: 0px 7px 18px #1810100d;
                    display: flex;
                    flex-direction: column;
                    min-width: 170px;
                    padding: 12px 0;
                    z-index: 10;
                    li{
                        list-style: none;
                        width: 100%;
                        display: block;
                        margin: 0;
                        &:hover{
                            a{
                                background: #EEEBFF;
                                color: #553CDF;
                            }
                        }
                        a{
                            color: #1F1F25;
                            font-size: 14px;
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            padding: 4px 12px;
                            margin: 2px 10px;
                            transition: all .3s;
                            border-radius: 4px;
                            background: transparent;
                            &.view-pro-file{
                                padding: 0 !important;
                                background: transparent;
                                margin: 0;
                            }
                            img{
                                width: 20px;
                            }
                        }
                    }
                }
            }
            &.switcher-currency{
                .has-child-menu{
                    .sub-menu{
                        min-width: 100px;
                    }
                }
            }
        }
        select{
            display: inline-block;
            max-width: max-content;
            background-color: transparent;
            border: none;
            color: #fff;
            font-size: 14px;
            position: relative;
            background-image: url(../images/header/01.svg) !important;
            &::after{
                position: absolute;
                content: '\f078';
                right: 0;
                top: 0;
                font-size: 15px;
                color: #fff;
            }
            &:focus{
                box-shadow: none;
            }
            option{
                color: #242424;
                font-size: 14px;
            }
        }
    }
}

.header-one-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: var(--color-white);
    border-radius: 80px;
    padding: 0 40px;
    border-width: 2px 2px 5px 2px;
    border-style: solid;
    border-color: var(--color-heading-1);
    @media(max-width:1200px){
        padding: 0 30px;
    }
    @media(max-width:450px){
        padding: 0 20px;
    }
    .left-side-header{
        display: flex;
        align-items: center;
        gap: 70px;
        @media #{$laptop-device} {
            gap: 20px;
        }
        @media #{$smlg-device} {
            gap: 20px;
        }
        .logo-area{
            padding: 20px 0;
            display: block;
            @media(max-width:450px){
                padding: 12px 0;
                width: 130px;
            }
        }
        .category-area{
            cursor: pointer;
            padding: 20px 0 ;
            .category-btn{
                padding: 6px 16px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                border: 1px solid #BBB1F2;
                background: #FBFAFF;
                gap: 8px;
                position: relative;
                @media #{$smlg-device} {
                    display: none;
                }
                span{
                    color: #553CDF;
                    font-weight: 500;
                }
                i{
                    color: #553CDF;
                }
            }
            &:hover{
                .category-sub-menu{
                    visibility: visible;
                    opacity: 1;
                    transform: translateZ(0) translateY(20px);
                }
            }
            .category-sub-menu{
                padding: 0;
                margin: 0;
                position: absolute;
                top: 100%;
                left: 0;
                visibility: hidden;
                opacity: 0;
                background: var(--color-white);
                transform: translateZ(0) translateY(30px);
                border-radius: 4px;
                transition: all .4s;
                box-shadow: 0px 7px 18px #1810100d;
                display: flex;
                flex-direction: column;
                min-width: 410px;
                padding: 17px 10px;
                li{
                    list-style: none;
                    width: 100%;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    ul{
                        padding: 0;
                        margin: 0;
                    }
                    a{
                        color: #1F1F25;
                        font-size: 16px;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding: 12px;
                        margin: 2px 10px;
                        transition: all .3s;
                        border-radius: 4px;
                        background: transparent;
                        span{
                            color: #1F1F25;
                        }
                        &:hover{
                            background: #EEEBFF;
                            span{
                                color: #553CDF;
                            }
                        }
                        img{
                            width: 45px;
                        }
                        h4{
                            color: #1F1F25;
                            font-weight: 500;
                            margin-bottom: 4px; 
                            font-size: 16px;
                            line-height: 1.1;
                        }
                        p{
                            margin-bottom: 0;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 1.1;
                        }
                    }
                }
            }
        }
    }
}

.header-right-area-one{
    display: flex;
    align-items: center;
    gap: 20px;
    @media #{$large-mobile} {
        gap: 6px;
    }
    .buttons-area{
        display: flex;
        align-items: center;
        gap: 10px;
        @media #{$sm-layout} {
            display: none;
        }
        .rts-btn{
            padding: 14px 31px;
            font-size: 14px;
            font-weight: 700;
            line-height: 1;
        }
    }
    .actions-area{
        display: flex;
        align-items: center;
        a.rts-btn{
            padding: 13px 25px;
            margin-left: 30px;
            @media #{$smlg-device} {
                display: none;
            }
        }
        .menu-btn{
            cursor: pointer;
            margin-left: 30px;
        }
        .search-btn{
            cursor: pointer;
            padding-right: 15px;
            position: relative;
            &::after{
                content: "";
                position: absolute;
                width: 1px;
                height: 20px;
                background: var(--color-primary);
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                opacity: .5;
            }
            i{
                color: #000;
            }
        }
        .cart{
            padding-left: 15px;
            i{
                color: var(--color-primary);
                font-size: 22px;
                position: relative;
                margin-top: 8px !important;
                cursor: pointer;
                &::after{
                    position: absolute;
                    content: "2";
                    right: -8px;
                    bottom: 14px;
                    height: 18px;
                    width: 18px;
                    background: var(--color-primary);
                    border-radius: 50%;
                    font-size: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                }
            }
        }
    }
}

.main-nav-one ul li.has-dropdown .megamenu-hub li ul:first-child::after{
    @media only screen and (max-width: 1320px) {
        display: none;
    }
}
.header-default{
    .header-top-one-wrapper{
        .header-top-one{
            .right-information{
                .social-area{
                    display: flex;
                    align-items: center;
                    gap: 21px;
                    padding: 12px 0;
                    margin: 0;
                    li{
                        list-style: none;
                        margin: 0;
                    }
                }
            }
        }
    }
    .header-main{
        background: var(--color-white);
        border-width: 1px 0;
        border-style: solid;
        border-color: var(--color-heading-1);
        .header-one-wrapper{
            border: none;
            padding: 0;
        }
    }
}
.header-two{
    .header-top-one-wrapper{
        background: #262261;
    }
}


.container-full{
    width: 100%;
    margin: auto;
    padding: 0 15px;
}


// header transparent
.header-transparent{
    position: relative;
    width: 100%;
    z-index: 100;
    padding: 15px 0;
    .header-tranaparent-main-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .right-area{
            display: flex;
            align-items: center;
            gap: 30px;
            .rts-btn{
                padding: 10px 22px;
            }
        }
    }
    .main-nav-one ul li a.nav-link {
        color: #ffffff !important;
    }
}

.menu-btn{
    cursor: pointer;
    svg{
        rect{
            fill: var(--color-primary-2);
        }
    }
}
.admin-top-area-right{
    .search-btn{
        i{
            color: var(--color-primary) !important;
            font-size: 20px;
            position: relative;
            &::after{
                position: absolute;
                content: "2";
                right: -8px;
                bottom: 10px;
                height: 18px;
                width: 18px;
                background: var(--color-primary);
                border-radius: 50%;
                font-size: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
            }
        }
    }
}

.header-one.v-5 .header-top-one-wrapper{
    background: #F6F6F6;
    .left-information a{
        color: #1F1F25;
        font-size: 14px;
        font-weight: 400;
        i{
            color: var(--color-primary);
            font-weight: 500;
        }
    }
    &.with-admin{
        @media #{$sm-layout} {
            display: block;
        }
        .left-information{
            .email.none-sm{
                @media #{$large-mobile} {
                    display: none;
                }
            }
        }
        .actions-area{
            @media #{$small-mobile} {
                display: none;
            }
        }
    }
}

.right-information.admin-panel{
    .has-child-menu{
        a{
            i{
                color: var(--color-primary);
            }
            span{
                color: #1F1F25;
                font-size: 16px;
                font-weight: 400;
            }
        }
        .sub-menu{
            min-width: 230px !important;
            left: -92% !important;
            @media #{$large-mobile} {
                left: -210% !important;
            }
            .user-profile{
                .profile-picture{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 15px;
                    img{
                        width: 52px;
                        min-width: 52px;
                        height: 52px;
                        border-radius: 100%;
                        border: 2px solid #2f57ef21;
                        box-shadow: 0px 6px 34px rgba(215, 216, 222, 0.41);
                        padding: 3px;
                    }
                    .info{
                        margin-top: -15px;
                        .name{
                            margin-bottom: 0;
                            line-height: 18px;
                        }
                        a{
                            padding: 0;
                            color: var(--color-primary);
                            font-weight: 500;
                            position: absolute;
                            &::after{
                                position: absolute;
                                content: '';
                                left: 0;
                                bottom: 2px;
                                height: 1px;
                                background: var(--color-primary);
                                width: 0%;
                                transition: .3s;
                            }
                            &:hover{
                                &::after{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            li{
                a{
                    display: flex;
                    align-items: center;
                    padding: 2px 12px !important;
                    i{
                        flex-basis: 30px;
                        font-size: 18px;
                    }
                    p{
                        font-size: 14px;
                        color: #08070F;
                    }
                }
            }
        }
    }
}

.admin-border-top{
    border-top: 1px solid #e7e7e7;
    margin-top: 15px !important;
    padding-top: 7px;
}