// service area style
.rts-service-area{
    .section-inner{
        margin-top: 60px;
    }
    &.v-2{
        .section-inner{
            position: relative;
            z-index: 1;
            .service-image-area{
                position: absolute;
                max-width: max-content;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
            }
            @media(max-width:1200px){
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }
            .service-image-area{
                @media(max-width:1200px){
                    position: unset;
                    transform: none;
                }
            }
        }
    }
}
.service-wrapper{
    text-align: center;
    @media(max-width:991px){
        margin-bottom: 40px;
    }
    .wrapper-inner{
        max-width: max-content;
        margin: auto;
        border: 1px dashed var(--color-primary-2);
        padding: 15px;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        transition: all .3s;
        &:hover{
            .image-area{
                .overlay-shape{
                    opacity: 1;
                }
            }
        }
        .image-area{
            max-width: max-content;
            border-radius: 50%;
            position: relative;
            z-index: 1;
            transition: all .3s;
            img{
                border-radius: 50%;
            }
            .overlay-shape{
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .6);
                top: 0;
                left: 0;
                border-radius: 50%;
                z-index: 0;
                transition: all .3s;
                opacity: 0;
                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 0;
                }
            }
            .overlink{
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                top: 0;
                left: 0;
                z-index: 1;
            }
        }
    }
    .service-title{
        margin-top: 40px;
        margin-bottom: 0;
        @media(max-width:991px){
            br{
                display: none;
            }
        }
    }
}
.service-wrapper-style-2{
    padding: 0;
    margin: 0;
    .wrapper-list{
        list-style: none;
        display: flex;
        align-items: center;
        gap: 30px;
        margin-bottom: 70px;
        margin-top: 0;
        @media(max-width:450px){
            gap: 10px;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .icon{
            width: 80px;
            height: 80px;
            line-height: 80px;
            border-radius: 50%;
            text-align: center;
            display: block;
        }
        .text{
            flex-basis: 75%;
            .heading-title{
                margin-bottom: 5px;
                @media(max-width:450px){
                    font-size: 18px;
                }
            }
        }
    }
}

.rts-video-area{
    background: url(../images/banner/video-bg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 300px 0;
    position: relative;
    z-index: 1;
    &::after{
        content: '';
        position: absolute;
        z-index: -1;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .video-btn-area{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        .video-play-button{
            width: 118px;
            height: 118px;
            background: none;
            border: none;
            &::after{
                display: none;
            }
            .icon{
                animation: rotateIt 6s linear infinite;
            }
        }
        .round{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }
        .play-icon{
            position: absolute;
            top: 48%;
            left: 52%;
            transform: translate(-52%, -48%);
            height: 51px;
        }
    }
}