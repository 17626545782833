// breadcrumb style here

.rts-breadcrumb-area{
    background: url(../images/banner/breadcrumb-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%);
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 0;
        left: 0;
    }
    .breadcrumb-main-wrapper{
        a{
            color: var(--color-white);
            &.active{
                color: var(--color-primary);
            }
        }
        i{
            color: var(--color-white);
        }
        .title{
            color: var(--color-white);
            margin-top: 15px;
            margin-bottom: 0;
        }
    }
}