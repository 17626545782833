// course style here
.rts-class-area{
    padding-bottom: 20px;
    .section-title-area{
        margin-bottom: 55px;
        .section-title{
            margin-bottom: 10px;
        }
    }
    &.area-2{
        padding-bottom: 120px;
    }
}
.class-wrapper{
    position: relative;
    z-index: 1;
    &::after{
        content: '';
        position: absolute;
        width: 101%;
        height: 102%;
        bottom: -6px;
        right: -6px;
        z-index: -1;
        border-radius: 10px;
        background: var(--color-heading-1);
    }
    .wrapper-inner{
        background: var(--color-primary-2);
        border-radius: 10px;
        padding: 35px 40px 40px 35px;
        border: 2px solid var(--color-heading-1);
        position: relative;
        z-index: 1;
        @media(max-width:1200px) and (min-width:992px){
            padding: 35px 20px;
        }
        @media(max-width:450px){
            padding: 35px 20px;
        }
        .icon{
            margin-bottom: 30px;
            height: 60px;
        }
        .title{
            color: var(--color-white);
            margin-bottom: 10px;
            @media(max-width:1200px) and (min-width:992px){
                font-size: 20px;
            }
        }
        p{
            color: var(--color-white);
            margin-bottom: 0;
            &.period{
                margin-bottom: 15px;
            }
        }
        .shape-icon{
            position: absolute;
            top: 20px;
            right: 26px;
            opacity: .3;
        }
    }
    &.two{
      .wrapper-inner{
        background: var(--color-primary-3);
      }  
    }
    &.three{
      .wrapper-inner{
        background: var(--color-primary-4);
      }  
    }
    &.four{
      .wrapper-inner{
        background: var(--color-primary-5);
      }  
    }
}
.rts-course-area{
    padding: 230px 0 180px;
    position: relative;
    z-index: 1;
    .section-title-area{
        .section-title{
            margin-bottom: 10px;
        }
        .desc{
            color: var(--color-heading-1);
            font-size: 18px;
            line-height: 28px;
        }
    }
    .slider-inner{
        margin-top: 40px;
        .swiper{
            padding: 20px;
        }
        .swiper-paginations{
            width: max-content;
            position: absolute;
            bottom: 120px;
            left: 50%;
            transform: translateX(-50%);
            .swiper-pagination-bullet{
                width: 30px;
                height: 4px;
                border-radius: 0;
                background: #C4C4C4;
                opacity: 1;
                &.swiper-pagination-bullet-active{
                    background: var(--color-heading-1);
                }
            }
        }
    }
    .course-wrapper{
        position: relative;
        z-index: 1;
        &::after{
            content: '';
            position: absolute;
            width: 101%;
            height: 101%;
            bottom: -5px;
            right: -4px;
            z-index: -1;
            border-radius: 10px;
            background: var(--color-heading-1);
        }
        .wrapper-inner{
            border-radius: 10px;
            border: 2px solid var(--color-heading-1);
            background: var(--color-primary-2);
            overflow: hidden;
            .image{
                border-bottom: 2px solid var(--color-heading-1);
            }
            .content{
                padding: 40px 30px 40px 35px;
                @media(max-width:450px){
                    padding: 30px 20px 30px 20px;
                }
                .title{
                    font-size: 24px;
                    color: var(--color-white);
                    @media(max-width:1300px) and(min-width:1140px){
                        font-size: 20px;
                    }
                    @media(max-width:900px) and(min-width:768px){
                        font-size: 20px;
                    }
                }
                .desc{
                    color: var(--color-white);
                    font-size: 18px;
                    margin-bottom: 25px;
                }
                .feature-wrapper{
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    gap: 30px;
                    .wrapper-list{
                        list-style: none;
                        color: var(--color-white);
                        position: relative;
                        padding-left: 15px;
                        font-size: 16px;
                        margin: 0;
                        line-height: 1;
                        @media(max-width:1300px) and(min-width:1140px){
                            font-size: 14px;
                        }
                        @media(max-width:900px) and(min-width:768px){
                            font-size: 14px;
                        }
                        &::after{
                            content: '';
                            position: absolute;
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background: var(--color-white);
                            left: 0;
                            top: 5px;
                        }
                    }
                }
            }
        }
        &.two{
            .wrapper-inner{
                background: var(--color-primary-5);
            }
        }
        &.three{
            .wrapper-inner{
                background: var(--color-primary-3);
            }
        }
    }
    &.style-2{
        padding: 0;
        .section-title-area{
            .desc{
                color: var(--color-body);
            }
        }
        .slider-inner{
            position: relative;
            z-index: 1;
            .swiper{
                padding: 0 5px 50px 5px;
            }
            .swiper-btn{
                width: 60px;
                height: 60px;
                z-index: 1;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: all .3s;
                &:hover{
                    .inner{
                        background: var(--color-primary);
                        svg{
                            path{
                                fill: var(--color-heading-1);
                            }
                        }
                    }
                }
                &::after{
                    display: none;
                }
                &::before{
                    content: '';
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: -1;
                    background: var(--color-heading-1);
                    bottom: -2px;
                    right: -2px;
                }
                .inner{
                    width: 60px;
                    height: 60px;
                    line-height: 57px;
                    border-radius: 50%;
                    text-align: center;
                    background: var(--color-primary-2);
                    border: 2px solid var(--color-heading-1);
                    svg{
                        path{
                            fill: var(--color-white);
                            transition: all .3s;
                        }
                    }
                }
                &.swiper-button-prev2{
                    left: -120px;
                }
                &.swiper-button-next2{
                    right: -120px;
                }
            }
        }
    }
    .shape{
        &.one{
            top: 45%;
            left: 6%;
        }
        &.two{
            top: 30%;
            right: 10%;
        }
    }
    &.style-3{
        padding: 120px 0;
        .section-title-area{
            .desc{
                color: var(--color-body);
            }
        }
    }
}
.rating-main-wrapper{
    padding: 30px;
    border-radius: 4px;
    border: 1px solid  #DDD8F9;
    @media #{$large-mobile} {
        padding: 20px;
    }
}
.course-wrapper-style-2{
    position: relative;
    z-index: 1;
    &::after{
        content: '';
        position: absolute;
        width: 101%;
        height: 101%;
        bottom: -5px;
        right: -3px;
        z-index: -1;
        border-radius: 10px;
        background: var(--color-heading-1);
    }
    .wrapper-inner{
        border-radius: 10px;
        border: 2px solid var(--color-heading-1);
        overflow: hidden;
        .image{
            border-bottom: 2px solid var(--color-heading-1);
            position: relative;
            z-index: 1;
            img{
                width: 100%;
            }
            .save-icon i {
                font-weight: 300;
                position: absolute;
                z-index: 3;
                color: #fff;
                top: 18px;
                right: 18px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s;
                z-index: 1;
                cursor: pointer;
            }
        }
        .content{
            padding: 26px 20px 26px 18px;
            .meta-wrapper{
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                gap: 20px;
                margin-bottom: 23px;
                .wrapper-list{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .desc{
                        margin-bottom: 0;
                        color: var(--color-white);
                    }
                }
            }
            .title{
                font-size: 18px;
                color: var(--color-white);
                line-height: 26px;
                margin-bottom: 10px;
            }
            .review-area{
                display: flex;
                align-items: center;
                gap: 12px;
                margin-bottom: 20px;
                .star-rating-area{
                    display: flex;
                    align-items: center;
                    margin: 0;
                    padding: 0;
                    gap: 5px;
                    li{
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                        i{
                            color: #FFD335;
                        }
                    }
                }
                .desc{
                    color: var(--color-white);
                }
            }
            .bottom-wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .author-area{
                    display: flex;
                    align-items: center;
                    gap: 14px;
                    .author-name{
                        color: var(--color-white);
                        font-weight: 800;
                        font-family: var(--font-secondary);
                    }
                }
                .price{
                    margin-bottom: 0;
                    color: var(--color-white);
                    font-size: 20px;
                }
            }
        }
    }
    &.inner{
        &::after{
            border-radius: 0;
        }
        .wrapper-inner{
            border-radius: 0;
        }
    }
}
.course-wrapper-list-style{
    position: relative;
    z-index: 1;
    .wrapper-inner{
        border-radius: 10px;
        border: 2px solid var(--color-heading-1);
        overflow: hidden;
        display: flex;
        align-items: center;
        .image{
            position: relative;
            z-index: 1;
            .save-icon i {
                font-weight: 300;
                position: absolute;
                z-index: 3;
                color: #fff;
                top: 18px;
                right: 18px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s;
                z-index: 1;
                cursor: pointer;
            }
        }
        .content{
            padding: 26px 50px 26px 30px;
            flex-basis: 75%;
            @media(max-width:991px){
                padding: 20px 50px 20px 30px;
            }
            @media(max-width:450px){
                padding: 20px;
            }
            .meta-wrapper{
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                gap: 20px;
                margin-bottom: 23px;
                @media(max-width:991px) and (min-width:768px){
                    margin-bottom: 15px;
                }
                .wrapper-list{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .desc{
                        margin-bottom: 0;
                        color: var(--color-white);
                    }
                }
            }
            .title{
                color: var(--color-white);
                margin-bottom: 10px;
                @media(max-width:1200px) and (min-width:992px){
                    font-size: 30px;
                    line-height: 40px;
                }
                @media(max-width:991px) and (min-width:768px){
                    font-size: 20px;
                    line-height: 30px;
                }
                @media(max-width:450px){
                    font-size: 20px;
                    line-height: 30px;
                }
            }
            .review-area{
                display: flex;
                align-items: center;
                gap: 12px;
                margin-bottom: 20px;
                .star-rating-area{
                    display: flex;
                    align-items: center;
                    margin: 0;
                    padding: 0;
                    gap: 5px;
                    li{
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        font-size: 16px;
                        i{
                            color: #FFD335;
                        }
                    }
                }
                .desc{
                    color: var(--color-white);
                }
            }
            .bottom-wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .author-area{
                    display: flex;
                    align-items: center;
                    gap: 14px;
                    .author-name{
                        color: var(--color-white);
                        font-weight: 800;
                        font-family: var(--font-secondary);
                    }
                }
                .price{
                    margin-bottom: 0;
                    color: var(--color-white);
                    font-size: 20px;
                }
            }
        }
    }
    &.inner{
        &::after{
            border-radius: 0;
        }
        .wrapper-inner{
            border-radius: 0;
            @media(max-width:767px){
                flex-direction: column;
                align-items: flex-start;
            }
            .image{
                @media(max-width:767px){
                    width: 100%;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
}
.button-group{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 60px;
    flex-wrap: wrap;
    .button{
        padding: 7px 12px;
        line-height: 1;
        background: var(--color-primary-5);
        border-width: 2px 4px 4px 2px;
        border-style: solid;
        border-color: var(--color-heading-1);
        max-width: max-content;
        color: var(--color-white);
        transition: all .3s;
        &:hover{
            color: var(--color-heading-1);
            background: var(--color-primary);
        }
        &.is-checked{
            color: var(--color-heading-1);
            background: var(--color-primary);
        }
    }
}
.portfolio_wrap{
    .course-wrapper-style-2{
        &::after{
            border-radius: 0;
            right: -3px;
        }
        .wrapper-inner{
            border-radius: 0;
        }
    }
}
.class-wrapper-style-2{
    position: relative;
    border: 1px dashed var(--color-primary-2);
    border-radius: 10px;
    padding: 10px;
    @media(max-width:1200px) and (min-width:992px){
        padding: 5px;
    }
    z-index: 1;
    .wrapper-inner{
        border-radius: 5px;
        padding: 40px 28px ;
        position: relative;
        z-index: 1;
        @media(max-width:1200px) and (min-width:992px){
            padding: 40px 15px;
        }
        .icon{
            margin-bottom: 30px;
            height: 60px;
        }
        .title{
            color: var(--color-white);
            margin-bottom: 10px;
            @media(max-width:1200px) and (min-width:992px){
                font-size: 24px;
            }
        }
        p{
            color: var(--color-white);
            margin-bottom: 0;
            &.period{
                margin-bottom: 15px;
            }
        }
        .button-area{
            .rts-btn{
                border-radius: 6px;
                border: 1px solid var(--color-white);
                line-height: 1;
                font-size: 15px;
                padding: 12px 15px;
                color: var(--color-white);
                max-width: max-content;
                margin: 25px auto auto;
                transition: all .3s;
                &:hover{
                    background: var(--color-primary);
                    border: 1px solid var(--color-primary);
                    color: var(--color-heading-1);
                }
            }
        }
        .shape-icon{
            position: absolute;
            top: 20px;
            right: 26px;
            opacity: .3;
        }
    }
    &.two{
        border: 1px dashed var(--color-primary-3);
    }
    &.three{
        border: 1px dashed var(--color-primary-4);
    }
    &.four{
        border: 1px dashed var(--color-primary-5); 
    }
}
.rts-course-default-area{
   .rts-pagination-area-2{
    margin-top: 60px;
    ul{
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        @media(max-width:450px){
            gap: 25px;
        }
        li{
            list-style: none;
            position: relative;
            max-width: max-content;
            margin: 0;
            color: var(--color-heading-1);
            font-weight: 600;
            z-index: 1;
            &.active{
                position: relative;
                z-index: 1;
                &::after{
                    content: '';
                    position: absolute;
                    z-index: 1;
                    background: var(--color-heading-1);
                    width: 10px;
                    height: 2px;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            .inner{
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 50%;
                text-align: center;
                background: var(--color-white);
                border: 2px solid var(--color-heading-1);
                color: var(--color-heading-1);
                transition: all .3s;
                &:hover{
                    background: var(--color-primary);
                }
                a{
                    display: block;
                    color: var(--color-primary);
                    z-index: 1;
                    &:hover{
                        color: var(--color-heading-1);
                    }
                }
            }
            &.button{
                &::before{
                    content: '';
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: -1;
                    background: var(--color-heading-1);
                    bottom: -2px;
                    right: -2px;
                }
            }
        }
    }
   } 
}
.rts-course-details-area{
    .left-side-content{
        .video-area{
            margin-bottom: 40px;
            .image{
                position: relative;
                z-index: 1;
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.2);

                }
                .video-btn-area{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    .vedio-icone{
                        .video-play-button{
                            width: 80px;
                            height: 80px;
                            line-height: 90px;
                            background: var(--color-white);
                            border: none;
                            @media(max-width:450px){
                                width: 60px;
                                height: 60px;
                                line-height: 66px;
                            }
                            i{
                                font-size: 28px;
                                color: var(--color-heading-1);
                                @media(max-width:450px){
                                    font-size: 22px;
                                }
                            }
                            &::after{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.course-content-wrapper{
    .title{
        font-size: 24px;
    }
    p.disc{
        margin-bottom: 30px;
    }
    .module-wrapper{
        padding: 30px;
        border-width: 2px 4px 4px 2px;
        border-style: solid;
        border-color: var(--color-heading-1);
        margin-bottom: 50px;
        @media #{$small-mobile} {
            padding: 0;
            border: none;
        }
        .inner-content{
            display: flex;
            align-items: flex-start;
            gap: 50px;
            @media(max-width:1366px) and (min-width:992px){
                flex-direction: column;
                gap: 0;
            }
            @media #{$large-mobile} {
                flex-direction: column;
                gap: 0;            
            }
            @media(max-width:768px){
                gap: 0;
                flex-direction: column;
            }
        }
        .single-wrapper{
            .single-codule{
                display: flex;
                align-items: start;
                gap: 8px;
                margin: 10px 0;
                i{
                    margin-top: 6px;
                }
            }
        }
    }
}
.rating-top-main-wrapper{
    display: flex;
    gap: 50px;
    @media(max-width:1366px) and (min-width:1200px){
        gap: 25px;
    }
    @media(max-width:1200px) and (min-width:992px){
        flex-direction: column;
    }
    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
    }
    @media(max-width:768px){
        flex-direction: column;
    }
}
.rating-area-main-wrapper{
    padding: 30px;
    border-width: 2px;
    border-style: solid;
    border-color: var(--color-heading-1);
    text-align: center;
    flex-basis: 30%;
    @media #{$large-mobile} {
        width: 100%;
    }
    .title{
        color:  var(--color-heading-1);
        font-size: 80px;
        font-style: normal;
        font-weight: 800;
        line-height: 80px;
        margin-bottom: 5px;
        @media(max-width:1366px) and (min-width:1200px){
            font-size: 70px;
            line-height: 70px;
        }
        @media #{$smlg-device} {
            font-size: 70px;
        }
    }
    .stars-wrapper{
        margin-bottom: 10px;
        i{
            color: #FFA41C;
        }
    }
    span{
        color: #110C2D;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }
}

.rts-course-details-area{
    .rating-main-wrapper{
        padding: 30px;
        border-width: 2px 4px 4px 2px;
        border-style: solid;
        border-color: var(--color-heading-1);
        border-radius: 0;
        @media #{$large-mobile} {
            padding: 20px;
        }
        .progress-wrapper-main{
            flex-basis: 69%;
            @media #{$large-mobile} {
                width: 100%;
            }
            .single-progress-area-h{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 13px 0;
                width: 100%;
                @media #{$large-mobile} {
                    flex-direction: column;
                    align-items: flex-start;
                }
                .progress{
                    width: 70%;
                    border-radius: 6px;
                    .progress-bar{
                        background: #FFA41C;
                    }
                }
            }
        }
    }
}

.course-content-wrapper-main{
    .title{
        margin-bottom: 10px;
    }
    .accordion{
        .accordion-item{
            border-width: 2px 4px 4px 2px;
            border-style: solid;
            border-color: var(--color-heading-1);
            margin-bottom: 15px;
            .accordion-body{
                padding: 20px;
              .play-vedio-wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 7px 0;
                .left{
                    i{
                        color: var(--color-primary-5);
                    }
                    span{
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px;
                        color: var(--color-heading-1);
                        text-decoration-line:underline;
                        @media(max-width:450px){
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                }
                .right{
                    .play{
                        color:  var(--color-primary-5);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px;
                        text-decoration-line: underline;
                        margin-right: 20px;
                    }
                }
              }  
            }
        }
        .accordion-header{
            button{
                padding: 15px 25px 15px 50px;
                background: transparent;
                display: flex;
                align-items: center;
                box-shadow: none;
                justify-content: space-between;
                position: relative;
                z-index: 1;
                @media(max-width:576px){
                    flex-direction: column;
                    align-items: self-start;
                }
                &[aria-expanded="true"]{
                    border-bottom: 1px solid #DDD8F9;
                }
                &:focus{
                    box-shadow: none;
                }
                &::after{
                    position: absolute;
                    content: '\f078';
                    top: 17px;
                    left: 20px;
                    color: var(--color-heading-1);
                    background: none;
                    font-family: 'Font Awesome 6 Pro';
                    font-size: 16px;
                    height: auto;
                    width: auto;
                }
                span{
                    display: block;
                    color:  #110C2D;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px;
                    @media(max-width:450px){
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
            .accordion-button:not(.collapsed)::after{
                content: '\f077';
            }
        }
    }
}

.indevidual-rating-area{
    padding-top: 30px;
    border-top: 1px solid var(--color-heading-1);
    margin-top: 40px;
    .author-area{
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 25px;
        img{
            border-width: 2px 3px 3px 2px;
            border-style: solid;
            border-color: var(--color-heading-1);
        }
        @media #{$small-mobile} {
            flex-direction: column;
            align-items: flex-start;
        }
        span{
            color:  var(--color-heading-1);
            font-family: var(--font-secondary);
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 26px;
        }
        .stars{
            display: flex;
            align-items: center;
            gap: 3px;
            i{
                color: #FFA41C;
            }
            span{
                color: var(--color-body);
                font-size: 15px;
                font-weight: 500;
            }
        }
    }
    p.disc{
        margin-bottom: 25px;
    }
    .like-love-area{
        display: flex;
        align-items: center;
        gap: 15px;
        a{
            display:block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: var(--color-heading-1);
            border-width: 2px 4px 4px 2px;
            border-color: var(--color-heading-1);
            border-style: solid;
            background: var(--color-white);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;
            &:hover{
                background: var(--color-primary);
            }
        }
    }
}
.single-instructor-area-details{
    padding: 20px;
    border-width: 2px 4px 4px 2px;
    border-style: solid;
    border-color: var(--color-heading-1);
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
    @media(max-width:1366px) and (min-width:1200px){
        gap: 10px;
        padding: 20px 15px;
    }
    @media(max-width:1200px) and (min-width:992px){
        flex-direction: column;
        align-items: flex-start;
    }
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }
    &:hover{
        .thumbnail{
            img{
                transform: scale(1.15);
            }
        }
    }
    .thumbnail{
        display: block;
        min-width: max-content;
        overflow: hidden;
        border-width: 2px 4px 4px 2px;
        border-style: solid;
        border-color: var(--color-heading-1);
        @media #{$sm-layout} {
            min-width: 100%;
        }
        img{
            transition: .3s;
            @media #{$sm-layout} {
                width: 100%;
            }
        }
    }
    .inner-instrustor-area{
        .title{
            margin-bottom: 5px;
        }
        .deg{
            color:  #737477;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }
        .stars-area-wrapper{
            display: flex;
            align-items: center;
            gap: 30px;
            margin: 12px 0;
            @media(max-width:1366px) and (min-width:1200px){
                gap: 10px;
            }
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
            }
            .stars-area{
                span{
                    color: #110C2D;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                }
                i{
                    color: #FFA41C;
                }
            }
        }
        p.disc{
            margin-bottom: 20px;
        }
        .follow-us{
            display: flex;
            align-items: center;
            ul{
                margin-left: 30px;
                padding: 0;
                display: flex;
                align-items: center;
                gap: 15px;
                list-style: none;
                margin-top: 0;
                margin-bottom: 0;
                li{
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}
.course-details-btn-wrapper{
    padding-bottom: 30px;
    border-bottom: 1px solid #DDD8F9;
    ul{
        padding-left: 0;
        margin: 0;
        border: none;
        display: flex;
        align-items: center;
        gap: 10px;
        @media #{$sm-layout} {
            flex-wrap: wrap;
            gap: 10px !important;
        }
        li{
            margin: 0;
            padding: 0;
            button.nav-link{
                padding: 15px 20px;
                border: none;
                border-radius: 4px;
                border-width: 2px 4px 4px 2px;
                border-style: solid;
                border-color: var(--color-heading-1);
                font-size: 16px;
                font-weight: 500;
                color: var(--color-heading-1);
                &.active{
                    background: var(--color-primary);
                    color: var(--color-heading-1);
                    border-width: 2px 4px 4px 2px;
                    border-style: solid;
                    border-color: var(--color-heading-1);
                    &.meterals{
                        position: relative;
                        &::after{
                            position: absolute;
                            left: 0;
                            width: 120%;
                            content: '';
                            height: 3px;
                            bottom: -18px;
                            background: var(--color-primary);
                        }
                    }
                }
                &:hover{
                    background: var(--color-primary);
                    color: var(--color-heading-1);
                    border-width: 2px 4px 4px 2px;
                    border-style: solid;
                    border-color: var(--color-heading-1);
                }
            }
        }
    }
    &.full-width{
        ul{
            gap: 15px;
            li{
                flex-basis: 25%;
                @media #{$sm-layout} {
                    min-width: max-content;
                }
                button{
                    border-radius: 0;
                }
            }
        }
    }
}
.right-course-details{
    margin-left: 50px;
    @media(max-width:1200px){
        margin-left: 10px;
    }
    .course-side-bar{
        padding: 30px;
        border-width: 2px 5px 5px 2px;
        border-style: solid;
        border-color: var(--color-heading-1);
        background: #fff;
        position: relative;
        @media #{$sm-layout} {
            padding: 20px;
        }
        .thumbnail{
            position: relative;
            img{
                @media #{$md-layout} {
                    width: 100%;
                }
                @media #{$sm-layout} {
                    width: 100%;
                }
            }
        }
        .price-area{
            display: flex;
            align-items: center;
            gap: 15px;
            margin-top: 35px;
            margin-bottom: 20px;
            .title{
                margin-bottom: 0;
                color:  #110C2D;
                font-size: 32px;
                font-style: normal;
                font-weight: 800;
                line-height: 26px;
            }
            h4{
                margin-bottom: 0;
                color:  #737477;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
            }
            .discount{
                padding: 3px 10px;
                border-radius: 3px;
                background: var(--color-primary);
                color: var(--color-heading-1);
            }
        }
        .clock-area{
            i,
            span{
                color: #BC0303;
            }
        }
        .rts-btn{
            margin-bottom: 20px;
            margin-top: 20px;
            display: block;
            width: 100%;
            max-width: 100%;
            text-align: center;
        }
        .what-includes{
            span.m{
                display: flex;
                justify-content: start;
                padding: 10px 0;
                padding-bottom: 25px;
            }
            .title{
                color: var(--color-heading-1);
                font-size: 24px;
                font-style: normal;
                font-weight: 800;
                line-height: 24px;
                margin-bottom: 10px;
                @media(max-width:450px){
                    font-size: 22px;
                    line-height: 22px;
                }
            }
            .single-include{
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #DDD8F9;
                padding-bottom: 15px;
                padding-top: 15px;
                @media(max-width:450px){
                    flex-direction: column;
                    align-items: flex-start;
                }
                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }
                .left{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
                .right{
                    span{
                        @media(max-width:1200px) and (min-width:992px){
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
.course-single-information{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #DDD8F9;
    padding-top: 15px;
    &.last{
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
    }
    .title{
        margin-bottom: 20px;
        color: var(--color-heading-1);
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 26px;
    }
    .body{
        .author{
            margin: 15px 0;
            display: flex;
            align-items: center;
            gap: 15px;
        }
        .single-check{
            display: flex;
            align-items: self-start;
            gap: 12px;
            margin: 15px 0;
            color:  #737477;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            i{
                color: var(--color-primary-5);
                margin-top: 7px;
            }
        }
        .tags-wrapper{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            span{
                display: block;
                padding: 10px 16px;
                border: 1px solid #DDD8F9;
                color: #110C2D;
                font-size: 14px;
                border-radius: 2px;
                font-style: normal;
                font-weight: 400;
                line-height: 1;
                cursor: pointer;
                transition: .3s;
                &:hover{
                    background: var(--color-primary);
                    color: #fff;
                }
            }
        }
    }
    .social-share-course-side-bar{
        ul{
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            gap: 25px;
            li{
                list-style: none;
            }
        }
    }
}
#exampleModal-login {
top: 30%;
}

.login-pupup-modal .modal-dialog {
max-width: 430px;
}
.login-pupup-modal .modal-content {
padding: 30px;
border-radius: 10px;
}
.login-pupup-modal .modal-content .modal-header {
border: none;
}
.login-pupup-modal .modal-body {
margin-top: 20px;
}
.login-pupup-modal .modal-body form input {
margin-bottom: 25px;
height: 45px;
border-radius: 6px;
border: 1px solid #DDD8F9;
}
.login-pupup-modal .modal-body form input:focus {
border-color: var(--color-primary);
}
.login-pupup-modal .modal-body form .align-items-center label::before {
background: transparent;
background: transparent;
border: 1px solid #737477 !important;
}
.login-pupup-modal .modal-body form .align-items-center input[type=checkbox]:checked ~ label::before {
background: var(--color-primary);
}
.login-pupup-modal .modal-body form label {
font-size: 14px;
}
.login-pupup-modal .modal-body form button {
max-width: 100%;
padding: 11px;
}
.login-pupup-modal .modal-body form .dont-acc {
font-size: 14px;
}
.login-pupup-modal .modal-body form .dont-acc a {
margin-left: 15px;
color: var(--color-primary);
}
.login-pupup-modal .modal-footer {
border: none;
}
.login-pupup-modal .modal-footer button {
max-width: 100%;
}

// gallery style here
.rts-gallery-area{
    .section-inner{
        margin-top: 55px;
    }
}
.gallery-wrapper{
    position: relative;
    z-index: 1;
    border-width: 2px 5px 5px 2px;
    border-style: solid;
    border-color: var(--color-heading-1);
    img{
        @media(max-width:768px){
            width: 100%;
        }
    }
    &:hover{
        .gallery-image{
            img{
                transform: scale(110%);
            }
            .item-overlay{
               opacity: 1;
               visibility: visible;
            }
        }
        .center-icon{
            opacity: 1;
        }
    }
    .gallery-image{
        overflow: hidden;
        display: block;
        z-index: 1;
        cursor: pointer;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        .item-overlay{
            position: absolute;
            top: 50%;
            width: 62px;
            height: 62px;
            opacity: 0;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            visibility: hidden;
            -webkit-transition: 0.3s ease-in;
            -o-transition: 0.3s ease-in;
            transition: 0.3s ease-in;
            &::after{
                content: '';
                position: absolute;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background: var(--color-heading-1);
                bottom: -1px;
                right: -2px;
                z-index: -1;
            }
            span{
                color: #ffff;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 60px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                border: 2px solid var(--color-heading-1);
                border-radius: 50%;
                background: var(--color-white);
                transform: translate(-50%, -50%);
                z-index: 1;
                i{
                    color: var(--color-heading-1);
                    font-size: 20px;
                }
            }
        }
    }
    .center-icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .3s;
        opacity: 0;
        z-index: 1;
    }
}

.gallery-wrapper-style-2{
    position: relative;
    z-index: 1;
    transition: all .4s;
    border-radius: 10px;
    overflow: hidden;
    &::after{
        content: '';
        position: absolute;
        width: 90%;
        height: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(103%);
        background: rgba(0, 0, 0, .3);
        z-index: 1;
        transition: all .4s;
        opacity: 0;
        border-radius: 10px;
    }
    .overlink{
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 0;
        left: 0;
        z-index: 2 !important;
        display: block;
    }
    img{
        @media(max-width:768px){
            width: 100%;
        }
    }
    &:hover{
        &::after{
            opacity: 1;
            transform: translate(-50%, -50%) scale(100%);
        }
        .gallery-image{
            img{
                transform: scale(110%);
            }
            .item-overlay{
               opacity: 1;
               visibility: visible;
            }
        }
        .center-icon{
            opacity: 1;
        }
    }
    .gallery-image{
        overflow: hidden;
        display: block;
        z-index: 1;
        cursor: pointer;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        .item-overlay{
            position: absolute;
            top: 50%;
            width: 62px;
            height: 62px;
            opacity: 0;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            visibility: hidden;
            -webkit-transition: 0.3s ease-in;
            -o-transition: 0.3s ease-in;
            transition: 0.3s ease-in;
            z-index: 3;
            span{
                color: #ffff;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 60px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                border: 2px solid var(--color-primary-2);
                border-radius: 50%;
                background: var(--color-white);
                transform: translate(-50%, -50%);
                z-index: 3;
                i{
                    color: var(--color-primary-2);
                    font-size: 20px;
                    font-weight: 500;
                }
            }
        }
    }
    .center-icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .4s;
        opacity: 0;
        z-index: 1;
    }
}

.rts-programs-area{
    .section-inner{
        margin-top: 60px;
    }
}
.programs-wrapper{
    .image{
        position: relative;
        z-index: 1;
        img.main{
            border-radius: 5px;
            width: 100%;
        }
        .shape-icon{
            width: 90px;
            height: 90px;
            line-height: 80px;
            text-align: center;
            display: block;
            border-radius: 50%;
            border: 5px solid #FFEFE5;
            position: absolute;
            bottom: -40px;
            right: 30px;
        }
    }
    .content{
        margin-top: 35px;
        .title{
            margin-bottom: 10px;
            a{
                font-size: 28px;
                line-height: 38px;
                text-decoration: underline;
                text-underline-offset: 3px;
                text-decoration-color: transparent;
                transition: all .3s;
                color: var(--color-heading-1);
                &:hover{
                    text-decoration-color: var(--color-heading-1);
                }
            }
        }
    }
}
