// banner style one here

.banner-area-one{
    background: linear-gradient(180deg, #A157FF 0%, #6B34C4 100%);
    height: auto;
    display: flex;
    overflow: hidden;
    position: relative;
    margin: auto;
    z-index: 1;

    @media #{$smlg-device} {
        height: auto;
    }
    @media(max-width:991px){
        padding-top: 150px;
    }
    .title-banner{
        color: var(--color-white);
        font-size: 64px;
        font-weight: 800;
        line-height: 70px;
        margin-top: 0;
        letter-spacing: -2px;
        position: relative;
        @media(max-width:1300px) and (min-width:1200px){
            font-size: 58px;
        }
        @media (max-width:1199px) and (min-width:992px) {
            font-size: 48px;
            line-height: 60px;
        }
        @media(max-width:768px){
            font-size: 54px;
            line-height: 54px;

        }
        @media #{$large-mobile} {
            font-size: 42px;
            line-height: 1.2;
        }
        @media(max-width:450px){
            font-size: 32px;
        }
        img{
            position: absolute;
            left: -40px;
            top: -30px;
            @media #{$large-mobile} {
                display: none;
            }
        }
        span{
            color: var(--color-primary);
            @media(max-width:1300px) and (min-width:1200px){
                font-size: 58px;
            }
            @media (max-width:1199px) and (min-width:992px) {
                font-size: 48px;
                line-height: 48px;
            }
            @media(max-width:768px){
                font-size: 54px;
                line-height: 54px;

            }
            @media #{$large-mobile} {
                font-size: 42px;
                line-height: 1.2;
            }
            @media(max-width:450px){
                font-size: 32px;
            }
        }
    }
    .shape-image{
        .shape{
            position: absolute;
            z-index: -1;
            @media #{$sm-layout} {
                display: none;
            }
            @media #{$large-mobile} {
                display: none;
            }
            &.one{
                left: 4%;
                top: 15%;            
            }
            &.two{
                left: 6%;
                bottom: 28%;
            }
            &.three{
                left: 40%;
                bottom: 30%;
            }
            &.four{
                left: 50%;
                bottom: 45%;  
            }
            &.five{
                left: 48%;
                bottom: 15%;   
            }
            &.six{
                right: 5%;
                top: 30%;     
            }
            &.seven{
                right: 5%;
                bottom: 35%;            
            }
        }
        @media(max-width:1200px){
            display: none;
        }
    }
    .banner-bg-grid-shape{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: url(../images/banner/bg-grid.png);
        background-size: cover;
        background-repeat: repeat;
        z-index: -2;
    }
}
.banner-bg-shape{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
}
.banner-bg-shape-2{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
}
.banner-bg-shape-3{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
}

.banner-content-one{
    display: flex;
    align-items: center;
    padding: 300px 0 360px;
    @media(max-width:1200px){
        padding: 250px 0 200px 20px;
    }
    @media(max-width:991px){
        padding: 80px 0 200px 20px;
    }
    @media #{$sm-layout} {
        padding: 80px 0 120px 20px;
    }
    @media #{$large-mobile} {
        padding: 56px 0 120px 20px;
    }
    @media(max-width:450px){
        padding: 56px 0 120px;
    }
    p.disc{
        max-width: 575px;
        color: var(--color-white);
        font-size: 18px;
        line-height: 28px;
        @media #{$smlg-device} {
            min-width: 100%;
        }
        @media #{$large-mobile} {
            max-width: 100%;
        }
    }
    .banner-btn{
        display: flex;
        align-items: center;
        gap: 30px;
        @media(max-width:450px){
            flex-direction: column;
            align-items: self-start;
        }
        .video-btn-area{
            display: flex;
            align-items: center;
            gap: 15px;
            .intro{
                margin: 0;
                color: var(--color-white);
            }
        }
    }
}
.banner-content-two{
    align-items: center;
    padding: 200px 0;
    position: relative;
    z-index: 2;
    @media(max-width:450px){
        padding: 80px 0;
    }
    .pre-title{
        color: var(--color-white);
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 25px;
    }
    .title-banner{
        color: var(--color-white);
        font-size: 80px;
        font-weight: 900;
        line-height: 90px;
        @media(max-width:991px){
            font-size: 64px;
            line-height: 74px;
        }
        @media(max-width:768px){
            font-size: 58px;
            line-height: 68px;
        }
        @media(max-width:576px){
            font-size: 50px;
            line-height: 60px;
        }
        @media(max-width:450px){
            font-size: 32px;
            line-height: 42px;
            br{
                display: none;
            }
        }
        .one{
            color: var(--color-primary-2);
        }
        .two{
            color: var(--color-primary-3);
        }
    }
    p.disc{
        max-width: 670px;
        color: var(--color-white);
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 45px;
    }
    .banner-btn{
        align-items: center;
        gap: 30px;
    }
}
.banner-content-three{
    align-items: center;
    padding: 236px 0;
    position: relative;
    z-index: 2;
    .pre-title{
        color: var(--color-primary-5);
        gap: 8px;
        margin-bottom: 25px;
        font-size: 24px;
        font-family: var(--font-secondary);
        @media(max-width:450px){
            font-size: 20px;
        }
        span{
            color: var(--color-primary-3);
        }
    }
    .title-banner{
        color: #553CDF;
        font-size: 76px;
        font-weight: 800;
        line-height: 80px;
        margin-bottom: 0;
        @media(max-width:1366px){
            font-size: 64px;
            line-height: 70px;
        }
        @media(max-width:1200px){
            font-size: 50px;
            line-height: 56px;
        }
        @media(max-width:767px){
            font-size: 44px;
            line-height: 50px;
        }
        @media(max-width:577px){
            font-size: 40px;
            line-height: 46px;
        }
        @media(max-width:450px){
            font-size: 30px;
            line-height: 36px;
        }
        span{
            font-weight: 900;
        }
    }
    .banner-btn{
        margin-top: 60px;
    }
}
.banner-right-img{
    position: absolute;
    bottom: 0;
    z-index: 0;
    @media(max-width:991px){
        position: unset;
    }
    &.v-8{
        position: absolute;
        bottom: 0;
        right: 0%;
        display: block;
        width: max-content;
        height: auto;
        z-index: 1;
        @media(max-width:1200px){
            max-width: 550px;
        }
        @media(max-width:991px){
            position: relative;
            z-index: 1;
            order: -1;
            margin-bottom: 60px;
            max-width: 100%;
            width: auto;
        }
        .round-shape{
            position: absolute;
            top: 60%;
            left: 10px;
            transform: translateY(-60%);
            z-index: -1;
        }
    }
}

.banner-area-two{
    position: relative;
    z-index: 1;
    .banner-wrapper-two{
        position: relative;
        z-index: 2;
        &::after{
            content: '';
            position: absolute;
            z-index: -2;
            background: linear-gradient(90deg, #953a11 0%, rgba(50, 20, 6, 0) 64.29%);
            // backdrop-filter: blur(5px);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        &.banner-bg-image{
            background: url(../images/banner/banner-bg-3.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        &.banner-bg-image2{
            background: url(../images/banner/banner-bg-4.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        &.banner-bg-image3{
            background: url(../images/banner/banner-bg-5.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        &.banner-bg-image4{
            background: url(../images/banner/banner-bg-7.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        &.banner-bg-image5{
            background: url(../images/banner/banner-bg-8.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        &.banner-bg-image6{
            background: url(../images/banner/banner-bg-9.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    .shape-image{
        .shape{
            position: absolute;
            z-index: 1;
            @media #{$sm-layout} {
                display: none;
            }
            @media #{$large-mobile} {
                display: none;
            }
            &.one{
                left: 5%;
                top: 15%;            
            }
            &.two{
                left: 7%;
                bottom: 7%;
            }
            &.three{
                left: 45%;
                top: 20%;
            }
        }
    }
}
.banner-area-three{
    position: relative;
    z-index: 2;
    background: url(../images/banner/banner-bg-6.jpg);
    .banner-content-three{
        @media(max-width:1024px){
            display: flex;
            flex-direction: column;
            align-items: start;
            padding: 120px 0;
        }
    }
    .banner-wrapper-three{
        .banner-hero-image{
            position: absolute;
            bottom: 0;
            right: -7%;
            z-index: 1;
            @media(max-width:1024px){
                position: unset;
                margin-bottom: 50px;
            }
        }
        .shape-image{
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: -1;
            .svg-shape1{
                position: absolute;
                top: 11%;
                right: 7%;
            }
            .svg-shape2{
                position: absolute;
                bottom: 0;
                right: 20%;
            }
        }
    }
    .banner-slider-inner{
        position: relative;
        z-index: 2;
        max-width: 1920px;
        margin: auto;
        .shape-image{
            .shape{
                top: 8%;
                right: 38%;
            }
        }
        .swiper-btn{
            width: 100px;
            height: 100px;
            z-index: 1;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border: 1px dashed var(--color-primary-2);
            background: transparent;
            line-height: 100px;
            text-align: center;
            border-radius: 50%;
            color: var(--color-white);
            transition: all .3s;
            @media(max-width:1440px){
                width: 60px;
                height: 60px;
                line-height: 55px;
            }
            @media(max-width:1366px){
                display: none;
            }
            svg{
                @media(max-width:1600px){
                    width: 30px;
                }
                path{
                    transition: all .3s;
                    fill: var(--color-primary-2);
                }
            }
            &:hover{
                color: var(--color-heading-1);
                background: var(--color-primary-2);
                border: 1px solid var(--color-primary-2);
                svg{
                    path{
                        fill: var(--color-white);
                    }
                }
            }
            &::after{
                display: none;
            }
            &.swiper-button-prev3{
                left: 100px;
                @media(max-width:1600px){
                    left: 20px;
                }
            }
            &.swiper-button-next3{
                right: 100px;
                @media(max-width:1600px){
                    right: 20px;
                }
            }
        }
    }
}
.banner-slider-inner{
    position: relative;
    z-index: 1;
    .banner-content-two{
        opacity: 0;
        .pre-title{
            opacity: 0;
            animation: none;
            transition-delay: .8s;
        }
        .title-banner{
            opacity: 0;
            animation: none;
            transition-delay: 1.2s;
        }
        .disc{
            opacity: 0;
            animation: none;
            transition-delay: 1.7s;
        }
        .banner-btn{
            opacity: 0;
            animation: none;
            transition-delay: 2.2s;
        }
    }
    .swiper-slide-active{
        .banner-content-two{
            opacity: 1;
            .pre-title{
                animation: slideInUp3 .8s;
                animation-delay: .8s;
                opacity: 1;
            }
            .title-banner{
                animation: fadeInUp .8s ;
                animation-delay: 1.2s;
                opacity: 1;
            }
            .disc{
                animation: fadeInUp .5s ;
                animation-delay: 1.7s;
                opacity: 1;
            }
            .banner-btn{
                animation: fadeInUp .5s ;
                animation-delay: 2.2s;
                opacity: 1;
            }
        }
    }
    .swiper-paginations{
        width: max-content;
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        .swiper-pagination-bullet{
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #B3B3B3;
            opacity: 1;
            &.swiper-pagination-bullet-active{
                background: var(--color-primary);
            }
        }
    }

    .swiper-btn{
        width: 60px;
        height: 60px;
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid var(--color-white);
        background: transparent;
        line-height: 60px;
        text-align: center;
        border-radius: 50%;
        color: var(--color-white);
        transition: all .3s;
        &:hover{
            color: var(--color-heading-1);
            background: var(--color-primary);
            border: 1px solid var(--color-heading-1);
        }
        &::after{
            display: none;
        }
        &.swiper-button-prev{
            left: 100px;
            @media(max-width:1600px){
                left: 20px;
            }
            @media(max-width:1440px){
                bottom: 50px;
                top: unset;
                transform: none;
            }
            @media(max-width:450px){
                display: none;
            }
        }
        &.swiper-button-next{
            right: 100px;
            @media(max-width:1600px){
                right: 20px;
            }
            @media(max-width:1440px){
                bottom: 50px;
                top: unset;
                transform: none;
            }
            @media(max-width:450px){
                display: none;
            }
        }
    }
    .banner-content-three{
        opacity: 0;
        .pre-title{
            opacity: 0;
            animation: none;
            transition-delay: .8s;
        }
        .title-banner{
            opacity: 0;
            animation: none;
            transition-delay: 1.2s;
        }
        .banner-btn{
            opacity: 0;
            animation: none;
            transition-delay: 1.8s;
        }
        .banner-hero-image{
            opacity: 0;
            animation: none;
            transition-delay: 1.2s;
        }
    }
    .swiper-slide-active{
        .banner-content-three{
            opacity: 1;
            .pre-title{
                animation: slideInUp3 .8s;
                animation-delay: .8s;
                opacity: 1;
            }
            .title-banner{
                animation: fadeInUp .8s ;
                animation-delay: 1.2s;
                opacity: 1;
            }
            .banner-btn{
                animation: fadeInUp .5s ;
                animation-delay: 1.8s;
                opacity: 1;
            }
            .banner-hero-image{
                opacity: 1;
                animation: slideInRight 1s;
                animation-delay: 1.2s;
                @media(max-width:1024px){
                    animation: none;
                }
            }
        }
    }
}

.order-change{
    @media(max-width:991px){
        order: -1;
    }
}
.order-change2{
    @media(max-width:1024px){
        order: -1;
    }
}
.order-change3{
    @media(max-width:1200px){
        order: -1;
    }
}

.banner-area-four{
    position: relative;
    z-index: 1;
    height: 860px;
    @media(max-width:991px){
        height: unset;
    }
    .banner-slider-inner{
        height: 100%;
    }
    .swiper{
        height: 100%;
    }
    .swiper-slide{
        width: 100% !important;
        height: 100%;
    }
    .banner-wrapper-four{
        position: relative;
        z-index: 2;
        height: 100%;
        &.banner-bg-image4{
                background: url(../images/banner/banner-bg-7.jpg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            &.banner-bg-image5{
                background: url(../images/banner/banner-bg-8.jpg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            &.banner-bg-image6{
                background: url(../images/banner/banner-bg-9.jpg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        .banner-bg-image{
            width: 100%;
            height: 100%;
        }
    }
    .banner-content-four{
        align-items: center;
        padding: 160px 90px 209px;
        position: absolute;
        z-index: 2;
        bottom: -115px;
        left: 5%;
        text-align: center;
        background: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(5px);
        border-radius: 50%;
        opacity: 0;
        @media(max-width:1366px){
            padding: 160px 70px 180px;
        }
        @media(max-width:1200px){
            padding: 150px 50px 150px;
        }
        @media(max-width:991px){
            position: relative;
            left: unset;
            bottom: unset;
            transform: none;
            border-radius: 0;
            padding: 120px 80px 120px;
        }
        @media(max-width:576px){
            padding: 120px 20px 120px;
        }
        .inner{
            position: relative;
            z-index: 1;
            .content-shape{
                position: absolute;
                top: -120px;
                left: 55px;
                @media(max-width:768px){
                    top: -90px;
                    left: 10px;
                    width: 130px;
                }
            }
        }
        .content-shape{
            opacity: 0;
            animation: none;
            transition-delay: 2.5s;
        }
        .pre-title{
            color: var(--color-primary-3);
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: center;
            margin-bottom: 25px;
            opacity: 0;
            animation: none;
            transition-delay: .8s;
        }
        .title-banner{
            color: var(--color-heading-1);
            font-size: 64px;
            font-weight: 800;
            line-height: 70px;
            letter-spacing: -2px;
            opacity: 0;
            animation: none;
            transition-delay: 1.2s;
            @media(max-width:1366px){
                font-size: 50px;
                line-height: 56px;
            }
            @media(max-width:576px){
                font-size: 34px;
                line-height: 40px;
            }
        }
        p.disc{
            max-width: 670px;
            color: var(--color-heading-1);
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 45px;
            opacity: 0;
            animation: none;
            transition-delay: 1.8s;
            @media #{$smlg-device} {
                min-width: 100%;
            }
            @media #{$large-mobile} {
                max-width: 100%;
            }
            @media(max-width:768px){
                br{
                    display: none;
                }
            }
        }
        .banner-btn{
            align-items: center;
            gap: 30px;
            opacity: 0;
            animation: none;
            transition-delay: 2.2s;
            .rts-btn{
                margin: auto;
            }
        }
    }
    .shape-image{
        .shape{
            position: absolute;
            z-index: 1;
            @media #{$sm-layout} {
                display: none;
            }
            @media #{$large-mobile} {
                display: none;
            }
            &.one{
                left: 5%;
                top: 15%;            
            }
            &.two{
                left: 7%;
                bottom: 7%;
            }
            &.three{
                left: 45%;
                top: 20%;
            }
        }
    }
    .banner-slider-inner{
        .swiper-btn{
            width: 100px;
            height: 100px;
            z-index: 1;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border: 1px dashed var(--color-primary-2);
            background: transparent;
            line-height: 100px;
            text-align: center;
            border-radius: 50%;
            transition: all .3s;
            @media(max-width:1600px){
                width: 60px;
                height: 60px;
                line-height: 58px;
            }
            @media(max-width:991px){
                display: none;
            }
            &:hover{
                color: var(--color-heading-1);
                background: var(--color-primary-2);
                border: 1px solid var(--color-primary-2);
                svg{
                    path{
                        fill: var(--color-white);
                    }
                }
            }
            svg{
                @media(max-width:1600px){
                    width: 30px;
                }
                path{
                    fill: var(--color-primary-2);
                }
            }
            &::after{
                display: none;
            }
            &.swiper-button-prev{
                left: 100px;
                @media(max-width:1366px){
                    left: 30px;
                }
            }
            &.swiper-button-next{
                right: 100px;
                @media(max-width:1366px){
                    right: 30px;
                }
            }
        }
    }
    .swiper-slide-active{
        .banner-content-four{
            opacity: 1;
            .content-shape{
                opacity: 1;
                animation: zoomIn 1s;
                animation-delay: 2.5s;
            }
            .pre-title{
                animation: slideInUp3 .8s;
                animation-delay: .8s;
                opacity: 1;
            }
            .title-banner{
                animation: fadeInUp .8s ;
                animation-delay: 1.2s;
                opacity: 1;
            }
            .banner-btn{
                animation: fadeInUp .5s ;
                animation-delay: 2.2s;
                opacity: 1;
            }
            p.disc{
                opacity: 1;
                animation: fadeInUp 1s;
                animation-delay: 1.8s;
            }
        }
    }
}

